import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const components = {
  imageText: imageText,
  videoText: videoText,
  oneColumnText: oneColumnText,
  oneColumnImage: oneColumnImage,
  twoColumnsText: twoColumnsText,
  twoColumnsImage: twoColumnsImage,
}

// Note: Image and Text
function imageText({ section }) {
  const image = section.image[0]
  const imageFile = section.imageFile[0]
  const text = section.text

  const TextImage = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
    margin: 2rem auto;
    width: 100%;
    max-width: 68rem;

    @media (min-width: 46rem) {
      grid-template-columns: repeat(6, 1fr);
      gap: 4rem 3rem;
    }

    div {
      &:first-of-type {
        margin-bottom: 1rem;

        @media (min-width: 46rem) {
          margin-bottom: 0;
        }
      }
      &:last-of-type {
        margin-top: 1rem;

        @media (min-width: 46rem) {
          margin-top: 0;
        }
      }
    }
  `

  const TextContainer = styled.div`
    grid-column-end: span 1;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  `

  const ImageContainer = styled.div`
    grid-column-end: span 1;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
    }

    picture {
      display: flex;
      width: 100%;

      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        flex: 1;
      }
    }
  `

  return (
    <TextImage>
      <TextContainer dangerouslySetInnerHTML={{ __html: text }} />

      <ImageContainer>
        <Img
          fluid={imageFile.childImageSharp.fluid}
          alt={image.title}
          title={image.title}
        />
      </ImageContainer>
    </TextImage>
  )
}

// Note: Video and Text
function videoText({ section }) {
  const video = section.video[0]
  const text = section.text

  const VideoImage = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
    margin: 2rem auto;
    width: 100%;
    max-width: 68rem;
    overflow: auto;

    @media (min-width: 46rem) {
      grid-template-columns: repeat(6, 1fr);
      gap: 4rem 3rem;
      margin: 4rem auto;
    }

    div {
      &:first-of-type {
        margin-bottom: 1rem;

        @media (min-width: 46rem) {
          margin-bottom: 0;
        }
      }
      &:last-of-type {
        margin-top: 1rem;

        @media (min-width: 46rem) {
          margin-top: 0;
        }
      }
    }
  `

  const TextContainer = styled.div`
    grid-column-end: span 1;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  `

  const VideoContainer = styled.div`
    grid-column-end: span 1;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
    }

    video {
      width: 100%;
    }
  `

  return (
    <VideoImage>
      <TextContainer dangerouslySetInnerHTML={{ __html: text }} />

      <VideoContainer>
        <video muted playsInline loop autoPlay>
          <source src={video.url} type="video/mp4"></source>
        </video>
      </VideoContainer>
    </VideoImage>
  )
}

// Note: One Column/Full Width Image`
function oneColumnImage({ section }) {
  const image = section.image[0]
  const imageFile = section.imageFile[0]

  const ImageContainer = styled.div`
    display: block;
    margin: 2em auto;
    width: 100%;
    max-width: 68rem;
    overflow: auto;

    @media (min-width: 46rem) {
      margin: 4rem auto;
    }
  `

  return (
    <ImageContainer>
      <Img
        fluid={imageFile.childImageSharp.fluid}
        alt={image.title}
        title={image.title}
      />
    </ImageContainer>
  )
}

// Note: One Column/Full Width Text
function oneColumnText({ section }) {
  const text = section.text

  const TextContainer = styled.section`
    display: block;
    margin: 2em auto;
    width: 100%;
    max-width: 58rem;
    overflow: auto;

    @media (min-width: 46rem) {
      margin: 4rem auto;
    }
  `

  return <TextContainer dangerouslySetInnerHTML={{ __html: text }} />
}

// Note: Two Columns/Half Width Image(s)
function twoColumnsImage({ section }) {
  const leftImage = section.imageFile[0]
  const leftImageTitle = section.image[0].title
  const rightImage = section.imageFile[1]
  const rightImageTitle = section.image[1].title

  const ImageContainer = styled.div`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(3, 1fr);
    margin: 2rem auto;
    width: 100%;
    max-width: 68rem;
    overflow: auto;

    @media (min-width: 46rem) {
      grid-template-columns: repeat(6, 1fr);
      gap: 4rem;
    }
  `

  const ImageColumn = styled.div`
    grid-column-end: span 3;

    &:first-of-type {
      margin-bottom: 1rem;

      @media (min-width: 46rem) {
        margin-bottom: 0;
      }
    }
    &:last-of-type {
      margin-top: 1rem;

      @media (min-width: 46rem) {
        margin-top: 0;
      }
    }
  `

  return (
    <ImageContainer>
      <ImageColumn>
        <Img
          fluid={leftImage.childImageSharp.fluid}
          alt={leftImageTitle}
          title={leftImageTitle}
        />
      </ImageColumn>

      <ImageColumn>
        <Img
          fluid={rightImage.childImageSharp.fluid}
          alt={rightImageTitle}
          title={rightImageTitle}
        />
      </ImageColumn>
    </ImageContainer>
  )
}

// Note: Two Columns/Half Width Text(s)
function twoColumnsText({ section }) {
  const leftText = section.text
  const rightText = section.textRight

  return (
    <div className="note__section--half-width">
      <div dangerouslySetInnerHTML={{ __html: leftText }} />
      <div dangerouslySetInnerHTML={{ __html: rightText }} />
    </div>
  )
}

export default function NoteSection({ section }) {
  const ComponentName = components[section.typeHandle]

  return <ComponentName section={section} />
}
