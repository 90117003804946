import React from "react"

import WavyLink from "../fragments/wavy-link"

import styled from "@emotion/styled"

const SinglePostPagination = styled.section`
  border-top: 1px solid rgba(237, 237, 237, 1);
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-top: 3rem;
  max-width: 58rem;
  gap: 3rem 3rem;

  div {
    grid-column-end: span 3;

    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }

    p {
      color: ${props => props.theme.grays.mid};
      display: block;
      font-size: 0.7rem;
      clear: both;
      margin: 0 0 0.65rem 0;
      padding: 0;
    }

    a {
      line-height: 1.2rem;
    }
  }

  @media (min-width: 46rem) {
    margin: 4rem auto;
    padding: 5rem 0 0;

    div {
      p {
        display: none;
      }

      a {
        line-height: inherit;
      }

      &:first-of-type {
        a span {
          &:before {
            content: "🡐";
            display: inline-block;
            transition: transform 0.44s cubic-bezier(0.51, 0.92, 0.24, 1.15);
            transform: translateX(0rem);
            margin-right: 0.25rem;
            will-change: transform;
          }

          &:hover {
            &:before {
              transition: transform 0.44s cubic-bezier(0.51, 0.92, 0.24, 1.15);
              transform: translateX(-0.5rem);
            }
          }
        }
      }

      &:last-of-type {
        a span {
          &:after {
            content: "🡒";
            display: inline-block;
            transition: transform 0.44s cubic-bezier(0.51, 0.92, 0.24, 1.15);
            transform: translateX(0rem);
            margin-left: 0.25rem;
            will-change: transform;
          }

          &:hover {
            &:after {
              transition: transform 0.44s cubic-bezier(0.51, 0.92, 0.24, 1.15);
              transform: translateX(0.5rem);
            }
          }
        }
      }
    }
  }
`

function PostPagination(props) {
  const { prev, next } = props.data

  return (
    <SinglePostPagination>
      <div>
        {next && (
          <>
            <p>🡐 Older Note</p>
            <WavyLink
              href={`/notes/${next.slug}/`}
              title={next.title}
              text={next.title}
              textColor="blackText"
              lineColor="yellowLine blueLineHover"
            />
          </>
        )}
      </div>

      <div>
        {prev && (
          <>
            <p>Newer Note 🡒</p>
            <WavyLink
              href={`/notes/${prev.slug}/`}
              title={prev.title}
              text={prev.title}
              textColor="blackText"
              lineColor="yellowLine blueLineHover"
            />
          </>
        )}
      </div>
    </SinglePostPagination>
  )
}

export default PostPagination
