import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"
import NoteSection from "../components/notes/builder"
import { getPrettyDate, getStandardDate, getSchemaDate } from "../utils/dates"
import PostPagination from "../components/notes/post-pagination"

export const query = graphql`
  query($slug: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    craft {
      entries(slug: $slug) {
        title
        slug
        url
        uri
        postDate
        dateCreated
        dateUpdated
        ... on Craft_notes_notes_Entry {
          seoTitle
          seoDescription
          image: seoImage {
            url(width: 720, height: 475, quality: 100, immediately: true)
          }
          imageFile {
            childImageSharp {
              fixed(width: 600, quality: 90) {
                src
              }
            }
          }
          introduction
          noteContent {
            ... on Craft_noteContent_imageText_BlockType {
              typeHandle
              text
              image {
                title
                url(width: 1400, quality: 100, immediately: true)
              }
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            ... on Craft_noteContent_videoText_BlockType {
              typeHandle
              text
              video {
                title
                url
              }
            }
            ... on Craft_noteContent_oneColumnText_BlockType {
              typeHandle
              text
            }
            ... on Craft_noteContent_oneColumnImage_BlockType {
              typeHandle
              image {
                title
                url(width: 1400, quality: 100, immediately: true)
              }
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            ... on Craft_noteContent_twoColumnsText_BlockType {
              typeHandle
              text
              textRight
            }
            ... on Craft_noteContent_twoColumnsImage_BlockType {
              typeHandle
              image {
                title
                url(width: 1400, quality: 100, immediately: true)
              }
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Note = props => {
  const noteData = props.data.craft.entries[0]
  const noteContent = props.data.craft.entries[0].noteContent

  const seoTitle = props.data.craft.entries[0].seoTitle
  const seoDescription = props.data.craft.entries[0].seoDescription
  const seoUrl = props.data.craft.entries[0].slug
  const seoImage =
    props.data.site.siteMetadata.siteUrl +
    props.data.craft.entries[0].imageFile[0].childImageSharp.fixed.src

  const NoteIntro = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    max-width: 58rem;
    padding-top: 6.5rem;
  `

  const NoteTitle = styled.h1`
    margin-bottom: 0;
    font-size: 3rem;

    @media (min-width: 46rem) {
      font-size: 3.75rem;
    }

    &:after {
      content: "";
      margin: 4rem 0 2rem;
      width: 3rem;
      height: 0.36rem;
      background-color: ${props => props.theme.primaryBlue};
      display: block;
      position: relative;
      left: -1.5rem;
    }
  `

  const NoteTime = styled.time`
    font-size: 0.8rem;
    color: ${props => props.theme.grays.mid};
    margin: 0 0 1rem;
    padding: 0;

    @media (min-width: 46rem) {
      margin: 0 0 3rem;
    }
  `

  const NoteLeadIn = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
  `

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`notes/${seoUrl}`}
        created={getSchemaDate(noteData.postDate)}
        updated={getSchemaDate(noteData.dateUpdated)}
        article
      />

      <div className="note-content">
        <NoteIntro>
          <NoteTime dateTime={getStandardDate(noteData.postDate)}>
            {getPrettyDate(noteData.postDate)}
          </NoteTime>

          <NoteTitle>{noteData.title}</NoteTitle>

          <NoteLeadIn
            dangerouslySetInnerHTML={{ __html: noteData.introduction }}
          />
        </NoteIntro>

        {noteContent.map((section, index) => (
          <NoteSection key={index} section={section} />
        ))}
      </div>

      <PostPagination data={props.pageContext} />
    </Layout>
  )
}

export default Note
